import $ from 'jquery';
import { bodyFix, bodyFixReset } from './_Functions.js';

$(window).on('load', () => {
	const btnOpenModal = $('.js-open-modal1');
	const btnCloseModal = $('.js-close-modal1');

	function closeModal1() {
		$('[data-modal1]').removeClass('is-active');
		bodyFixReset();
	}

	btnOpenModal.on('click', function (e) {
		e.preventDefault();
		$(`[data-modal1=${$(this).data('open-modal1')}]`).addClass('is-active');
		bodyFix();
	});

	btnCloseModal.on('click', () => closeModal1());

	$('[data-modal1]').on('click', function (e) {
		if ($(e.target).is('.c-modal1') || $(e.target).is('.c-modal1__wrap')) {
			closeModal1();
		}
	})

	$(window).on('scroll', () => {
		$('.c-modal1.is-active').css('left', -$(window).scrollLeft());
	});
});
