import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'slick-carousel';

$(function () {
	const initSlider01 = function (elem) {
		let init = false;

		const createSlider = function () {
			if (init === false) {
				init = new Swiper(elem, {
					loop: true,
					loopedSlides: 2,
					initialSlide: 1,
					slidesPerView: 1.6,
					centeredSlides: true,
				});
			}
		};

		if (window.settings.sp()) {
			createSlider();
		}

		$(window).on('resize-x-end', function () {
			if (window.settings.sp()) {
				createSlider();
			} else if (init !== false) {
				init.destroy();
				init = false;
			}
		});
	};

	$('.js-slider01').each(function () {
		initSlider01(this);
	});
});

$(function () {
	const cpnSlider01Elem = $('.c-slider01');
	if (cpnSlider01Elem.length) {
		cpnSlider01Elem.slick({
			infinite: true,
			centerMode: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			speed: 3000,
			// cssEase: 'linear',
			arrows: false,
			dots: false,
			variableWidth: true,
			pauseOnHover: false,
			draggable: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}
});

$(function () {
	const cpnSlider02Elem = $('.c-slider02');
	if (cpnSlider02Elem.length) {
		cpnSlider02Elem.slick({
			infinite: true,
			centerMode: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 2000,
			speed: 3000,
			// cssEase: 'linear',
			arrows: false,
			dots: false,
			variableWidth: true,
			pauseOnHover: false,
			draggable: false,
			rtl: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}
});
