import $ from 'jquery';

// tab1
$(document).ready(function () {
	$('.js-tab1').each(function () {
		$('.js-tab1-link', this).on('click', function (e) {
			e.preventDefault();
			let id = $(this).attr('href');

			$(this)
				.closest('.js-tab1-menu')
				.find('.js-tab1-item')
				.removeClass('is-active');
			$(this).parent().addClass('is-active');
			if (
				$(this)
					.closest('.js-tab1-menu')
					.find('.js-tab1-item')
					.hasClass('is-active')
			) {
				$(this).closest('.js-tab1').find('.js-tab1-content').hide();
				$(this)
					.closest('.js-tab1')
					.find('.js-tab1-content[data-id=' + id + ']')
					.show();
			}
		});
	});
});

// check url active tab
if (window.location.hash) {
	var hash = window.location.hash.substring(1);
	$(document).ready(function () {
		$('.js-tab1-link[href=' + hash + ']').trigger('click');
	});
}

// active tab with href inside page
$('.c-list1__link').delegate('', 'click', function () {
	let href = $(this).attr('href').substring(1);
	$('.js-tab1-link[href=' + href + ']').trigger('click');

	$('html, body').animate(
		{
			scrollTop: $('.js-tab1').offset().top - $('.c-header').height(),
		},
		0,
	);
});

// tab2
$(document).ready(function () {
	$('.js-tab2').each(function () {
		$('.js-tab2-link', this).on('click', function (e) {
			e.preventDefault();
			let id = $(this).attr('href');
			id = id.replace('#', '');

			$(this)
				.closest('.js-tab2-menu')
				.find('.js-tab2-item')
				.removeClass('is-active');
			$(this).parent().addClass('is-active');
			if (
				$(this)
					.closest('.js-tab2-menu')
					.find('.js-tab2-item')
					.hasClass('is-active')
			) {
				$(this).closest('.js-tab2').find('.js-tab2-content').hide();
				$(this)
					.closest('.js-tab2')
					.find('.js-tab2-content[data-id=' + id + ']')
					.show();
			}
		});
	});
});
