import $ from 'jquery';

$(window).scroll(function () {
	adjustBanner();
});

function adjustBanner() {
	let scrollTop = $(window).scrollTop();
	let heightScreen = $(window).height();
	let footerTop = $('.c-footer').offset().top - heightScreen;

	if (scrollTop > 200) {
		$('.js-banner1').addClass('is-active');
	} else {
		$('.js-banner1').removeClass('is-active');
	}
}
