import $ from 'jquery';

window.settings = {
	isObserver: typeof ResizeObserver === 'function',

	sp: function () {
		return window.innerWidth < 768;
	},

	pc: function () {
		return window.innerWidth >= 768;
	},

	scrollBarWidth: null,
	getScrollBarWidth: function () {
		if (window.settings.scrollBarWidth !== null) {
			return window.settings.scrollBarWidth;
		}

		let div = document.createElement('div');
		div.style.overflow = 'scroll';
		div.style.width = '100px';
		div.style.height = '100px';
		div.style.visibility = 'hidden';
		document.body.appendChild(div);
		window.settings.scrollBarWidth = div.offsetWidth - div.clientWidth;
		document.body.removeChild(div);
		//console.log('getScrollBarWidth');

		return window.settings.scrollBarWidth;
	},

	getOffsetTop: function (domEl) {
		let offsetTop = domEl.offsetTop;
		if (domEl.offsetParent) {
			offsetTop += window.settings.getOffsetTop(domEl.offsetParent);
		}
		return offsetTop;
	},

	resizeXWidth: window.innerWidth,
	resizeXTimeout: null,
	eventResizeX: function () {
		window.settings.scrollBarWidth = null;
		if (window.settings.resizeXWidth !== window.innerWidth) {
			clearTimeout(window.settings.resizeXTimeout);
			window.settings.resizeXWidth = window.innerWidth;
			$(window).trigger('resize-x');
			document.body.classList.add('is-resizing');
			window.settings.resizeXTimeout = setTimeout(function () {
				document.body.classList.remove('is-resizing');
				$(window).trigger('resize-x-end');
			}, 200);
		}
	},

	bodyFixed: function (fixed = true) {
		let bodyElem = $('body');
		let scrollBarWidth = window.settings.getScrollBarWidth();
		if (fixed) {
			document.body.style.overflow = 'hidden';
			document.body.style.touchAction = 'none';
			document.body.style.paddingRight = scrollBarWidth + 'px';
			document.body.classList.add('is-body-fixed');
		} else {
			document.body.style.overflow = '';
			document.body.style.touchAction = '';
			document.body.style.paddingRight = '';
			document.body.classList.remove('is-body-fixed');
		}

		bodyElem.trigger('body-fixed', [fixed, scrollBarWidth]);
	},

	isBodyFixed: function () {
		return document.body.classList.contains('is-body-fixed');
	}
}

$(window).on('resize-x-end', function () {
	if (window.settings.isBodyFixed()) {
		document.body.style.paddingRight = window.settings.getScrollBarWidth() + 'px';
	}
});

$(window).on('load', function () {
	$(window).on('orientationchange', window.eventResizeX);
	if (window.settings.isObserver) {
		new ResizeObserver(window.settings.eventResizeX).observe(document.body);
	} else {
		$(window).on('resize', window.eventResizeX);
	}

	window.settings.resizeXWidth = window.innerWidth;
	$(window).trigger('resize-x');
});
