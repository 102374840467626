import $ from 'jquery';

$(window).on('load', () => {
	const wrapperEle = $('.l-wrapper');
	const wind = $(window);
	const footer = $('.js-footer');

	function checkFixedFooter() {
		setTimeout(() => {
			if (footer.length) {
				if (wrapperEle.innerHeight() < wind.innerHeight()) {
					footer.addClass('is-fixed');

				} else {
					footer.removeClass('is-fixed');
				}
				if ($('.js-footer.is-fixed').length) {
					const hFooter = $('.js-footer.is-fixed').innerHeight();

					if (wrapperEle.innerHeight() + hFooter >= wind.innerHeight()) {
						footer.removeClass('is-fixed');
					} else {
						footer.addClass('is-fixed');
					}
				}
			}
		}, 0);
	}

	checkFixedFooter();

	$(window).on('resize orientationchange', () => checkFixedFooter())
});
