import $ from 'jquery';

$('a[href*="#"]').on('click', function () {
	let locationPathname = location.pathname.replace(/^\//, '');
	let thisPathname = this.pathname.replace(/^\//, '');
	if (locationPathname === thisPathname && location.hostname === this.hostname && this.hash !== '') {
		let $target = $(this.hash);
		if (!$target.length) {
			$target = $('[name=' + this.hash.slice(1) + ']');
		}
		if ($target.length) {
			let header = $("header");
			let offsetTop = header.length ? header.height() : 0;
			let targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
		}
	}
});
