import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(function () {
	$('.js-calendar1-swiper').each(function () {
		const $title = $('.js-calendar1-title', this);
		const getTitle = function (swiper) {
			let item = swiper.slides[swiper.realIndex];
			let title = item.dataset.datetime;
			if ($title.length && title) {
				$title.text(title);
			}
		}
		new Swiper(this, {
			loop: false,
			autoHeight: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			on: {
				init: function (swiper) {
					getTitle(swiper);
				},
				slideChange: function (swiper) {
					getTitle(swiper);
				}
			}
		});
	});
});
