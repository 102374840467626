import $ from 'jquery';
import { bodyFix, bodyFixReset } from './_Functions.js';

$(window).on('load', () => {
	let click = false

	const btnOpenMenu = $('.js-open-menu')
	const btnCloseMenu = $('.js-close-menu')
	const header = $('.js-header')
	const headerContent = $('.c-header__content')
	const eleFixedHeader = $('.js-fixed-header')
	const mv2 = $('.c-mv2')
	const eleFixedHeaderPC = $('.js-fixed-header-pc')
	const eleFixedHeaderSP = $('.js-fixed-header-sp')

	if (btnOpenMenu.length) {
		btnOpenMenu.on('click', () => {
			if (!click) {
				header.addClass('is-active')
				bodyFix()
				click = true

			} else {
				header.removeClass('is-active')
				bodyFixReset()
				click = false
			}
		})

		btnCloseMenu.on('click', () => {
			header.removeClass('is-active is-fixed-active')
			click = false
			bodyFixReset()
		})

		if ($(window).outerWidth() < 768) {
			headerContent.css('height', $(window).innerHeight() - 60)
		}

		$(window).on('resize', () => {
			if ($(window).outerWidth() < 768) {
				headerContent.css('height', $(window).innerHeight() - 60)

			} else {
				headerContent.removeAttr('style')
			}
		})

		$(window).on('pageshow', function (event) {
			if (event.originalEvent.persisted) {
				header.addClass('disable-active')
				header.removeClass('is-active')

				setTimeout(() => {
					header.removeClass('disable-active')
				}, 0);
			}
		});
	}

	$(window).on('scroll', () => {
		let distance = 0

		if (eleFixedHeader.length) {
			distance = eleFixedHeader.offset().top + eleFixedHeader.innerHeight()
		}

		if (mv2.length) {
			distance = mv2.offset().top + mv2.innerHeight()
		}

		if (eleFixedHeaderPC.length) {
			if ($(window).outerWidth() > 767) {
				distance = eleFixedHeaderPC.offset().top + eleFixedHeaderPC.innerHeight()
			}
		}

		if (eleFixedHeaderSP.length) {
			if ($(window).outerWidth() < 768) {
				distance = eleFixedHeaderSP.offset().top + eleFixedHeaderSP.innerHeight()
			}
		}

		if ($('.page-component').length) {
			if ($(window).outerWidth() > 767) {
				distance = 80

			} else {
				distance = 60
			}
		}

		if ($(window).scrollTop() >= distance) {
			header.addClass('is-fixed')

		} else {
			header.removeClass('is-fixed is-fixed-active')
			click = false
		}
	})

	$(window).on('scroll', () => {
		$('.js-header.is-fixed').css('left', -$(window).scrollLeft())
		$('.js-header.is-fixed-active').css('left', -$(window).scrollLeft())
		$('.js-header.is-active').css('left', -$(window).scrollLeft())
	})
})
