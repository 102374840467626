import $ from 'jquery';

$(window).on('load', () => {
	const dropdowns = $('.js-dropdown')
	const contents = $('.js-dropdown-content')
	let click = false

	if (dropdowns.length) {
		dropdowns.each(function () {
			const _this = $(this)
			const content = _this.parent().find('.js-dropdown-content')
			const btns = _this.parent().find('.js-dropdown-btn')

			const offDropdown = () => {
				contents.removeClass('is-active')
				dropdowns.removeClass('is-active')
				click = false
			}

			_this.on('click', function () {
				if (!click) {
					content.addClass('is-active')
					_this.addClass('is-active')
					click = true

				} else {
					offDropdown()
				}
			})

			btns.on('click', function () {
				btns.removeClass('is-active')
				$(this).addClass('is-active')
				offDropdown()
			})

			$(window).on('click', (e) => {
				const isTarget = !$(e.target).is('.js-dropdown-content') && !$(e.target).is('.c-header__language__list') && !$(e.target).is('.c-header__language__item')

				if (isTarget) {
					if (!$(e.target).is('.js-dropdown')) {
						offDropdown()
					}
				}
			})
		})
	}
})


//c-faq1
$(document).ready(function () {
	const toggle = $('.js-toggle');
	const toggleContent = $('.js-toggle-content');

	toggleContent.hide();
	if (toggle.length) {
		toggle.each(function () {
			$(this).on('click', () => {
				$(this).parent('.c-faq1__item1').toggleClass('is-active');
				$(this).parent('.c-faq1__item1').find(toggleContent).slideToggle();
				$(this)
					.parent('.c-faq1__item1')
					.find('.c-icon1')
					.toggleClass('is-active');
			});
		});
	}
});
